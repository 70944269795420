import * as React from "react";
import Helmet from "react-helmet";
import { A2, A7, A8 } from "../components/fonts";
import Container from "../components/container";

const IndexPage = () => {
  return (
    <>
      <Helmet title="Request Demo" />
      <Container className="bg-wbSand min-h-screen">
        <div className="col-span-30 md:col-span-15 px-6 pb-12 mt-6 md:mt-32">
          <A2 className="text-wbTruss max-w-xl mb-16 md:mb-16 leading-none">
            See how <span className="text-wbBlue">Waybridge</span> will
            transform your supply chain.
          </A2>
          <A7 className="text-wbTruss max-w-sm mb-12 lg:pr-20">
            Fill out the form below and we&apos;ll get in touch to schedule a
            demo.
          </A7>
          <div className="antialiased">
            <form
              method="post"
              // eslint-disable-next-line react/no-unknown-property
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="request-demo"
            >
              <input type="hidden" name="bot-field" />
              <input
                type="text"
                name="name"
                placeholder="First and last name"
                className="focus:outline-none 
								w-full
								text-wbTruss placeholder-wbStone50 placeholder-opacity-100
								border-b border-wbStone focus:border-wbTruss hover:border-wbTruss
								transition duration-300
								bg-transparent
								pt-2
								mb-6
								"
                required
              />
              <input
                type="email"
                name="email"
                placeholder="Work email address"
                className="focus:outline-none 
								w-full
								text-wbTruss placeholder-wbStone50 placeholder-opacity-100
								border-b border-wbStone focus:border-wbTruss hover:border-wbTruss
								transition duration-300
								bg-transparent
								py-2 mb-6"
                required
              />
              <input
                type="text"
                name="company"
                placeholder="Company"
                className="focus:outline-none 
								w-full
								text-wbTruss placeholder-wbStone50 placeholder-opacity-100
								border-b border-wbStone focus:border-wbTruss hover:border-wbTruss
								transition duration-300
								bg-transparent
								py-2 mb-6"
                required
              />
              <input
                type="text"
                name="jobtitle"
                placeholder="Title"
                className="focus:outline-none 
								w-full
								text-wbTruss placeholder-wbStone50 placeholder-opacity-100
								border-b border-wbStone focus:border-wbTruss hover:border-wbTruss
								transition duration-300
								bg-transparent
								py-2 mb-6"
                required
              />
              {/* 
							this field is here to fix some weird thing with
							netlify where the last input of the form doesn't render.

							probably shouldn't stay this way.
							*/}
              <input
                type="text"
                name="hiddenfix"
                placeholder="Title"
                className="hidden focus:outline-none 
								w-full
								text-wbTruss placeholder-wbStone50 placeholder-opacity-100
								border-b border-wbStone focus:border-wbTruss hover:border-wbTruss
								transition duration-300
								bg-transparent
								py-2 mb-6"
              />
              <A8>
                <button
                  className="
									inline-block
									text-wbTruss
									hover:bg-wbTruss hover:text-wbSand
									border-wbTruss border-1
									transition transform duration-300
									antialiased
									px-2 py-2 mt-12 mb-12 md:mb-0
									rounded
									"
                  type="submit"
                >
                  Submit
                </button>
              </A8>
            </form>
          </div>
        </div>
        <div className="col-span-30 md:col-span-15 bg-product002 bg-cover bg-center min-h-half md:min-h-screen px-6"></div>
      </Container>
    </>
  );
};
export default IndexPage;
